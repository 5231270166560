<template>
  <date-range-picker
    ref="picker"
    :opens="opens"
    :locale-data="localeData"
    v-model="dateRange"
    :autoApply="false"
    :ranges="defaultRanges"
    @update="save()"
    @select="setValue"
  >
    <template #input="picker">
      <div class="period-wrap">Period</div>
      <div class="input-label-date-picker">
        <v-icon color="primary" small v-if="!picker.rangeText.length"
          >fal fa-calendar-alt</v-icon
        >
        {{ picker.rangeText.length > 3 ? editRanges(picker)  : "Choose dates" }}
        <v-icon color="primary" small v-if="picker.rangeText.length"
          >fal fa-angle-down</v-icon
        >
      </div>
    </template>
    <div class="hidden-md-and-up picker-header" slot="header" slot-scope="data">
      <v-btn text color="white" @click="data.clickCancel"
        ><v-icon color="white">fal fa-times</v-icon></v-btn
      >
      Period
    </div>
    <!--    ranges (new slot syntax) -->
    <template #ranges="ranges">
      <div class=" hidden-sm-and-down">
        <v-list class="ranges">
          <v-list-item-group
            mandatory
            v-model="selectedPreset"
            active-class="selected-preset"
          >
            <v-list-item
              v-for="(range, name) in ranges.ranges"
              :key="name"
              :value="name"
              @click="ranges.clickRange(range)"
            >
              <v-list-item-content>
                {{ name }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="hidden-md-and-up mobile-preset">
        <div class="select">
          <select
            id="standard-select"
            v-model="selectedPreset"
            @change="selectRange()"
          >
            <option
              v-for="(range, name) in ranges.ranges"
              :key="name"
              :value="name"
            >
              {{ name }}
            </option>
          </select>
          <span class="focus"></span>
        </div>
      </div>
    </template>

    <template #date="data">
      <span class="small">{{ data.date | dateCell }}</span>
    </template>
    <!--    footer slot-->
    <div slot="footer" slot-scope="data" class="slot">
      <div style="margin-left: auto">
        <v-btn text color="primary" @click="data.clickCancel">Annuller</v-btn>
        <v-btn
          text
          color="primary"
          @click="data.clickApply"
          v-if="!data.in_selection"
          >OK</v-btn
        >
      </div>
    </div>
  </date-range-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  props: {
    opens: {
      required: false,
      type: String,
      default: "left"
    },
    value: {
      required: false,
      type: Object
    },
    ranges: {
      required: false,
      type: Object
    }
  },
  components: { DateRangePicker },
  data() {
    const startDate = this.value.start_date;
    const endDate = this.value.end_date;
    return {
      selectedPreset: null,

      dateRange: { startDate, endDate },
      localeData: {
        format: "dd. mm. yyyy",
        daysOfWeek: ["So", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        monthNames: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        firstDay: 1
      },
      isSaved: false,
      mobilePresets: []
    };
  },
  computed: {
    defaultRanges() {
      const today = new Date();
      const yesterday = this.$moment(today).subtract(1, "days");
      const last7days = this.$moment(today).subtract(6, "days");
      const last30days = this.$moment(today).subtract(29, "days");
      const lastWeekStart = this.$moment(today)
        .subtract(1, "week")
        .isoWeekday(1)
        .startOf("isoweek");
      const lastWeekEnd = this.$moment(today)
        .subtract(1, "week")
        .isoWeekday(1)
        .endOf("isoweek");
      const lastMonthStart = this.$moment(today)
        .subtract(1, "month")
        .startOf("month");
      const lastMonthEnd = this.$moment(today)
        .subtract(1, "month")
        .endOf("month");
      const defaultData = {
        BRUGERDEFINERET: [null, null],
        "TODAY": [today, today],
        "YESTERDAY": [yesterday._d, yesterday._d],
        "LAST WEEK": [lastWeekStart._d, lastWeekEnd._d],
        "LAST MONTH": [lastMonthStart._d, lastMonthEnd._d],
        "LAST 7 DAYS": [last7days._d, today],
        "LAST 30 DAYS": [last30days._d, today]
      };
      return this.ranges ? this.ranges : defaultData;
    }
  },
  methods: {
    saveRanges() {
      this.isSaved = false;

      this.$refs.picker.clickedApply(true);
      this.isSaved = true;
    },
    save() {
      this.$emit("changePeriod", {
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
      });
    },
    editRanges(date) {
      return `${this.$moment(date.startDate).format("MMM DD")} - ${this.$moment(
        date.endDate
      ).format("MMM DD")}`;
    },
    setDefaultPresets() {
      this.mobilePresets = Object.entries(this.defaultRanges).map(
        ([key, value]) => ({
          label: key,
          value
        })
      );
    },
    selectRange() {
      this.$refs.picker.togglePicker(false);
      const data = this.defaultRanges[this.selectedPreset];
      this.$refs.picker.clickRange(data);
      if (!this.isSaved) this.$refs.picker.open = true;
    },
    setValue(data) {
      if (data && Object.keys(data).length) {
        const rangesArr = Object.values(this.defaultRanges).find(
          val =>
            moment(val[0]).format("YYYY-MM-DD") ===
              moment(data.startDate).format("YYYY-MM-DD") &&
            moment(val[1]).format("YYYY-MM-DD") ===
              moment(data.endDate).format("YYYY-MM-DD")
        );
        const rangeName = Object.keys(this.defaultRanges).find(
          key => this.defaultRanges[key] === rangesArr
        );
        this.selectedPreset = rangeName ? rangeName : "CUSTOM";
      } else {
        const rangesArr = Object.values(this.defaultRanges).find(
          val =>
            moment(val[0]).format("YYYY-MM-DD") === this.dateRange.startDate &&
            moment(val[1]).format("YYYY-MM-DD") === this.dateRange.endDate
        );
        const rangeName = Object.keys(this.defaultRanges).find(
          key => this.defaultRanges[key] === rangesArr
        );
        this.selectedPreset = rangeName ? rangeName : "CUSTOM";
      }
    }
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);
      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  mounted() {
    this.setValue(null);
    this.setDefaultPresets();
  }
};
</script>

<style scoped lang="scss">
.input-label-date-picker {
  display: flex;
  align-items: center;
  color: var(--v-primary-base) !important;
  font-size: 14px;
  background: rgba(89, 89, 166, 0.098) !important;
  padding: 4px 8px;
  border-radius: 8px;
  text-transform: capitalize;
  .v-icon {
    margin-left: 5px;
  }
}

.slot {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  bottom: 0px;
}
.picker-header {
  background-color: var(--v-primary-base) !important;
  padding: 14px 0;
  width: 100%;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.period-wrap {
  font-size: 14px;
  color: var(--v-greytxt-base) !important;
}
</style>
